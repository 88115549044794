<template>
  <div>
    <h3>
      <b>{{ $t('FAQ.rentfixgeneral') }}</b>
    </h3>
    <hr />
    <div v-html="$t('FAQ.rentfixgeneraldesc1')"></div>
    <br />
    <div v-html="$t('FAQ.rentfixgeneraldesc2')"></div>
    <br />
    <div v-html="$t('FAQ.rentfixgeneraldesc3')"></div>
    <hr />
  </div>
</template>

<script>
export default {};
</script>

<style></style>
